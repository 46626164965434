var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.state.loading),expression:"state.loading"}]},[_c('div',[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.themeType == "system" ? "系统主题" : ""))])]),(_vm.themeType == 'system')?[(_vm.state.grantedPolicies)?[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","flex-wrap":"wrap"}},_vm._l((_vm.state.themeColorList),function(item,index){return _c('span',{key:index,style:({
            display: 'inline-block',
            width: '16px',
            height: '16px',
            margin: '8px 8px 0 0',
            cursor: 'pointer',
            border: '1px solid #ddd',
            'border-radius': '2px',
            background: item.color,
            'line-height': '16px',
          }),on:{"click":function($event){return _vm.putTheme(item)}}},[(
              item.style == _vm.state.currentTheme.style &&
              !_vm.state.sysSetting.systemTheme.color &&
              !_vm.state.userSetting.systemTheme.color
            )?_c('i',{staticClass:"el-icon-check",staticStyle:{"color":"#f5f5f5"}}):_c('i')])}),0),(_vm.customColor)?_c('div',{staticStyle:{"line-height":"32px","display":"flex","flex-direction":"row","padding-top":"12px"}},[_c('span',[_vm._v("自定义颜色：")]),_c('el-color-picker',{staticClass:"theme-set",staticStyle:{"display":"inline-block"},attrs:{"size":"mini","predefine":_vm.predefineColors},on:{"change":_vm.changeColorSys},model:{value:(_vm.state.sysSetting.systemTheme.color),callback:function ($$v) {_vm.$set(_vm.state.sysSetting.systemTheme, "color", $$v)},expression:"state.sysSetting.systemTheme.color"}})],1):_vm._e()]:_c('div',[_vm._v("没有设置主题权限")])]:_vm._e(),(_vm.themeType == 'user')?[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","flex-wrap":"wrap"}},_vm._l((_vm.state.themeColorList),function(item,index){return _c('span',{key:index,style:({
          display: 'inline-block',
          width: '16px',
          height: '16px',
          margin: '8px 8px 0 0',
          cursor: 'pointer',
          border: '1px solid #ddd',
          'border-radius': '2px',
          background: item.color,
          'line-height': '16px',
        }),on:{"click":function($event){return _vm.changeColorUser(item.color)}}},[(item.color == _vm.state.userSetting.systemTheme.color)?_c('i',{staticClass:"el-icon-check",staticStyle:{"color":"#f5f5f5"}}):_c('i')])}),0),_c('div',{staticStyle:{"line-height":"32px","display":"flex","flex-direction":"row","padding-top":"12px"}},[_c('span',[_vm._v("自定义颜色：")]),_c('el-color-picker',{staticClass:"theme-set",staticStyle:{"display":"inline-block"},attrs:{"size":"mini","predefine":_vm.predefineColors},on:{"change":_vm.changeColorUser},model:{value:(_vm.state.userSetting.systemTheme.color),callback:function ($$v) {_vm.$set(_vm.state.userSetting.systemTheme, "color", $$v)},expression:"state.userSetting.systemTheme.color"}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }