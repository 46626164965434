<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import { Button, Divider, Avatar } from "element-ui";
import { baseConfig, _axios } from "vue-kst-auth";
import QRCode from "qrcode";
Vue.use(Button);
Vue.use(Divider);
Vue.use(Avatar);
export default {
  props: ["msg", "nav", "navItems", "sysSetting"],
  data() {
    return {
      navList: [],
      /** 活动地址 */
      activeMenu: "",
      /** 选中导航名称 */
      activedisplayName: "",
    };
  },
  render() {
    return (
      <div class="list-group">
        {this.navList.map((item, index) => {
          return (
            <a
              href={item.path ? item.path : "javascript:;"}
              target={this.linkTarget(item)}
              class={{
                "unit-head-menu": true,
                "is-active": item.isActive,
              }}
            >
              <span>{item.displayName}</span>
              {item.children ? (
                <i class="el-icon-arrow-down name-icon"></i>
              ) : null}

              {item.children ? (
                <div class="el-menu el-menu--popup">
                  {item.children.map((val, index) => {
                    return (
                      <a
                        key={index}
                        href={this.SystemUrl(val)}
                        class={{
                          "unit-head-menu": true,
                          hover: true,
                          is_Select: val.isActive,
                        }}
                        style="position: relative;"
                        target={this.linkTarget(val)}
                      >
                        {val.displayName}

                        {val.children ? (
                          <i
                            class="el-icon-arrow-right"
                            style="position: absolute; right:10px"
                          ></i>
                        ) : null}

                        {this.renderChildren(val, index)}
                      </a>
                    );
                  })}
                </div>
              ) : null}
            </a>
          );
        })}
      </div>
    );
  },
  created() {
    this.getNavList();
  },
  updated() {
    this.setNavActive();
  },
  /**方法 */
  methods: {
    /** 获取菜单列表 */
    getNavList() {
      this.setNavActive();
      if (baseConfig.getLoginState()) {
        let data = this.navItems;
        /** 定义新的url地址 */
        let newactiveMenu = this.activeMenu;
        if (!data.some((val) => val.path == newactiveMenu)) {
          const splitMenu = newactiveMenu.split("/");
          /** url中地址匹配到接口返回数据的序号 */
          let indexPath;
          // let splitMenuLength = ;
          for (let index = splitMenu.length; index > 0; index--) {
            newactiveMenu = splitMenu.slice(0, index).join("/");
            if (!newactiveMenu.endsWith("/")) {
              newactiveMenu += "/";
            }
            indexPath = data.findIndex(
              (val) =>
                val.path == newactiveMenu || val.path + "/" == newactiveMenu
            );
            // console.log(newactiveMenu, index);
            if (indexPath != -1) {
              // console.log("匹配到了", newactiveMenu);
              break;
            }
          }
        }
        if (!newactiveMenu || newactiveMenu == "/") {
          newactiveMenu = this.activeMenu;
        }
        data.forEach((item) => {
          if (typeof item.isActive == "undefined") {
            item.isActive = false;
          }
          if (item.path == newactiveMenu || item.path + "/" == newactiveMenu) {
            item.isActive = true;
            // 修改网页标题
            this.activedisplayName = item.displayName;
            if (item.parentId != null) {
              this.parentActive(data, item.parentId);
            }
          }
        });

        this.navList = this.translateDataToTree(data);

        // 修改网页标题
        // console.log(this.sysSetting);
        if (this.sysSetting) {
          switch (this.sysSetting.webTitle) {
            case "系统名称":
              console.log("系统名称", this.sysSetting.sysName);
              window.document.title = this.sysSetting.sysName || "";
              break;
            case "系统和导航":
              window.document.title = `${this.sysSetting.sysName || ""}${
                this.activedisplayName ? "-" : ""
              }${this.activedisplayName}`;
              break;

            default:
              window.document.title = this.activedisplayName;
              break;
          }
        } else {
          window.document.title = this.activedisplayName;
        }
        // _axios
        //   .get(`/oauth2/api/menuManagement/tenantMenus/current-list`)
        //   .then((res) => {});
      }
    },
    /** 父级导航选中高亮 */
    parentActive(data, id) {
      let parent = data.find((item) => item.id == id);
      parent.isActive = true;
      if (parent.parentId != null) {
        this.parentActive(data, parent.parentId);
      }
    },
    /**路径 */
    setNavActive() {
      this.activeMenu = window.location.pathname;
    },
    /** 链接打开方式 */
    linkTarget(item) {
      // console.log(item.target);
      let target = "_self";
      if (item.target != null && item.target) {
        target = item.target.toString().replace(/^__/, "_");
      }
      return target;
    },
    /**地址 */
    SystemUrl(url) {
      let userToken = baseConfig.getCookie("userToken");
      let tenant = baseConfig.getCookie("__tenant");

      if (url.path == "/system") {
        return `/system?userToken=${userToken}&__tenant=${tenant}`;
      }
      if (url.path == "/system/menu-management/tenant-menus") {
        return `/system?userToken=${userToken}&__tenant=${tenant}&returnUrl=${url.path}`;
      }
      if (url.path == "/system/identity/organization-units") {
        return `/system?userToken=${userToken}&__tenant=${tenant}&returnUrl=${url.path}`;
      } else {
        return url.path || "javascript:;";
      }
    },
    /** 渲染子导航数据 */
    renderChildren(item, index) {
      return item.children ? (
        <div
          class="el-menu el-menu--popup"
          style={{
            left: "200px",
            top: "0px",
            "min-height": `${item.children.length * 43 + 5}px`,
            "margin-top": "0",
            "padding-left": "6px",
            "box-shadow": "none",
            background: "none",
            border: "none",
          }}
        >
          <div
            class="el-menu el-menu--popup"
            style="display: block; margin-top: 0;"
          >
            {item.children.map((itemChildren, index) => {
              return (
                <a
                  key={index}
                  href={this.SystemUrl(itemChildren)}
                  class={{
                    "unit-head-menu": true,
                    hover: true,
                    is_Select: itemChildren.isActive,
                  }}
                  target={this.linkTarget(itemChildren)}
                >
                  {itemChildren.displayName}
                  {itemChildren.children ? (
                    <i
                      class="el-icon-arrow-right"
                      style="position: absolute; right:10px"
                    ></i>
                  ) : null}
                  {this.renderChildren(itemChildren, index)}
                </a>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      );
    },
    /** 数据结构改变 */
    translateDataToTree(data) {
      let parents = data.filter(
        (value) => value.parentId == "undefined" || value.parentId == null
      );
      let children = data.filter(
        (value) => value.parentId !== "undefined" && value.parentId != null
      );
      let translator = (parents, children) => {
        parents.forEach((parent) => {
          children.forEach((current, index) => {
            if (current.parentId === parent.id) {
              let temp = JSON.parse(JSON.stringify(children));
              temp.splice(index, 1);
              translator([current], temp);
              typeof parent.children !== "undefined"
                ? parent.children.push(current)
                : (parent.children = [current]);
            }
          });
        });
      };

      translator(parents, children);

      return parents;
    },
  },

  /**计算属性 */
  computed: {},
};
</script>
