










import { Component, Vue } from "vue-property-decorator";
interface State {
  /** 数据状态 */
  loading: boolean;
}
@Component
export default class Index extends Vue {
  /** 数据 */
  state: State = {
    loading: false,
  };
  /** 生命周期 */
  /** 方法 */
  /** 全屏 */
  fullScreen() {
    const element: any = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  }
  /** 退出全屏 */
  exitFullscreen() {
    const element: any = document;
    if (element.exitFullscreen) {
      element.exitFullscreen();
    } else if (element.msExitFullscreen) {
      element.msExitFullscreen();
    } else if (element.mozCancelFullScreen) {
      element.mozCancelFullScreen();
    } else if (element.webkitExitFullscreen) {
      element.webkitExitFullscreen();
    }
  }
  /** 切换全屏 */
  toggleFullScreen() {
    const element: any = document;
    const isFull = !!(
      element.webkitIsFullScreen ||
      element.mozFullScreen ||
      element.msFullscreenElement ||
      element.fullscreenElement
    );
    //!document.webkitIsFullScreen都为true。因此用!!
    if (isFull == false) {
      this.fullScreen();
    } else {
      this.exitFullscreen();
    }
  }
  /** 监听 */
  /** 计算属性 */
}
