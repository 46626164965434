













































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { InSysSetting, InThemeItem } from "@/plugins/ExportType";
import {
  JsonSettingApi,
  JsonSettingDto,
  UpdateJsonSettingDto,
} from "@/plugins/JsonSetting";
import { _axios, baseConfig } from "vue-kst-auth";
interface State {
  /** 主题授权 */
  grantedPolicies: boolean;
  /** 数据状态 */
  loading: boolean;
  /** 主题列表 */
  themeColorList: Array<InThemeItem>;
  /** 当前使用主题 */
  currentTheme: InThemeItem;
  /** 默认主题颜色变量 */
  // defaultColorVar: { [key: string]: string };
  /** 系统初始话颜色，该数据是整体替换颜色关键 */
  // systemColor: {
  //   sysPrimary: string;
  //   sysSuccess: string;
  //   sysinfo: string;
  //   syswarning: string;
  //   sysdanger: string;
  // };
  /** 租户系统配置 */
  sysSetting: InSysSetting;
  /** 系统配置数据，定义好后后期不能随意修改 */
  jsonData: JsonSettingDto;
  /** 个人用户系统配置 */
  userSetting: InSysSetting;
  /** 个人用户配置数据，定义好后后期不能随意修改 */
  userjsonData: JsonSettingDto;
}
@Component
export default class Index extends Vue {
  /** 系统主题配置，个人主题配置 */
  @Prop({ default: "system" }) themeType?: "system" | "user";
  /** 自定义主题颜色 */
  @Prop({ default: false }) customColor?: boolean;
  /** 快捷主题设置自动保存 */
  @Prop({ default: false }) autoSave?: boolean;
  /** 系统配置json API */
  JsonSettingApi: JsonSettingApi = new JsonSettingApi("/json_data", _axios);
  /** 数据 */
  state: State = {
    grantedPolicies: false,
    loading: false,
    themeColorList: [
      {
        name: "Style1",
        color: "#4ECC7A",
        successColor: "#4ECC7A",
        warningColor: "#F5BD5D",
        dangerColor: "#EC705F",
        infoColor: "#E6E6E6",
        style: 0,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      {
        name: "Style2",
        color: "#409EFF",
        successColor: "#67C23A",
        warningColor: "#E6A23C",
        dangerColor: "#F56C6C",
        infoColor: "#909399",
        style: 1,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      {
        name: "Style3",
        color: "#009688",
        successColor: "#67C23A",
        warningColor: "#E6A23C",
        dangerColor: "#F56C6C",
        infoColor: "#909399",
        style: 2,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      {
        name: "Style4",
        color: "#3a6af0",
        successColor: "#67C23A",
        warningColor: "#E6A23C",
        dangerColor: "#F56C6C",
        infoColor: "#909399",
        style: 3,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      {
        name: "Style5",
        color: "#113059",
        successColor: "#67C23A",
        warningColor: "#E6A23C",
        dangerColor: "#F56C6C",
        infoColor: "#909399",
        style: 4,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      {
        name: "Style6",
        color: "#9C27B0",
        successColor: "#67C23A",
        warningColor: "#E6A23C",
        dangerColor: "#F56C6C",
        infoColor: "#909399",
        style: 5,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
    ],
    currentTheme: {
      name: "Style1",
      color: "#4ECC7A",
      style: 0,
      boxedLayout: false,
      menuPlacement: 0,
      menuStatus: 1,
      publicLayoutStyle: 0,
    },
    // defaultColorVar: {},
    // systemColor: {
    //   sysPrimary: "#4ecc7a",
    //   sysSuccess: "#67C23A",
    //   sysinfo: "#909399",
    //   syswarning: "#f5bd5d",
    //   sysdanger: "#ec705f",
    // },
    sysSetting: {
      loginedUrl: "",
      kpiJumpSys:"",
      systemTheme: {
        name: "",
        color: "",
        successColor: "#4ECC7A",
        warningColor: "#F5BD5D",
        dangerColor: "#EC705F",
        infoColor: "#E6E6E6",
        style: 0,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      sysName: "",
      webTitle: "导航名称",
    },
    jsonData: new JsonSettingDto({
      name: "系统全局配置",
      display: "系统全局配置",
    }),
    userSetting: {
      loginedUrl: "",
      kpiJumpSys:"",
      systemTheme: {
        name: "",
        color: "",
        successColor: "#4ECC7A",
        warningColor: "#F5BD5D",
        dangerColor: "#EC705F",
        infoColor: "#E6E6E6",
        style: 0,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      sysName: "",
      webTitle: "导航名称",
    },
    userjsonData: new JsonSettingDto({
      name: "用户全局配置",
      display: "用户全局配置",
    }),
  };
  /** 生命周期 */
  created() {
    this.getsetting();
  }
  // mounted() {
  // }
  /** 方法 */
  /**
   * 计算颜色叠加值
   * c1: rgb颜色1
   * c2：rgb颜色2
   * ratio：c1颜色取百分比
   * */
  colourBlend = (c1: string, c2: string, ratio: number) => {
    ratio = Math.max(Math.min(Number(ratio), 1), 0);
    let r1 = parseInt(c1.substring(1, 3), 16);
    let g1 = parseInt(c1.substring(3, 5), 16);
    let b1 = parseInt(c1.substring(5, 7), 16);
    let r2 = parseInt(c2.substring(1, 3), 16);
    let g2 = parseInt(c2.substring(3, 5), 16);
    let b2 = parseInt(c2.substring(5, 7), 16);
    let r = Math.round(r1 * ratio + r2 * (1 - ratio));
    let g = Math.round(g1 * ratio + g2 * (1 - ratio));
    let b = Math.round(b1 * ratio + b2 * (1 - ratio));
    const rgbr = ("0" + (r || 0).toString(16)).slice(-2);
    const rgbg = ("0" + (g || 0).toString(16)).slice(-2);
    const rgbb = ("0" + (b || 0).toString(16)).slice(-2);
    return "#" + rgbr + rgbg + rgbb;
  };
  /** 动态计算element ui 中所有变量 */
  getColorVar(data: InThemeItem) {
    /** 默认系统色 */
    const defaultPrimaryColor = this.state.themeColorList[0].color;
    /** CSS变量字符串 */
    let colorStr = "";
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 92].forEach((item) => {
      /** 生成新的变量 */
      const newColor =
        item != 0
          ? this.colourBlend(
              "#ffffff",
              data.color,
              item / (item > 10 ? 100 : 10)
            )
          : data.color;
      /** 变量key */
      const colorKey = `--primary${item != 0 ? "-" + item : ""}`;
      colorStr += `${colorKey}: ${newColor};`;

      // this.state.defaultColorVar[colorKey] =
      //   item != 0
      //     ? this.colourBlend(
      //         "#ffffff",
      //         defaultPrimaryColor,
      //         item / (item > 10 ? 100 : 10)
      //       )
      //     : defaultPrimaryColor;
    });
    colorStr += `--primary-bg1: var(--primary-8);`;
    // const { sysSuccess, sysinfo, syswarning, sysdanger, sysPrimary } =
    //   this.state.systemColor;
    if (data.successColor) {
      colorStr += `--success:${data.successColor};`;
      // this.state.defaultColorVar["--success"] = sysSuccess;
    }
    // 改变警告色，危险色，信息色
    [0, 8, 9].forEach((item) => {
      if (data.warningColor) {
        const key = `--warning${item != 0 ? "-" + item : ""}`;
        colorStr += `${key}:${
          item != 0
            ? this.colourBlend("#ffffff", data.warningColor, item / 10)
            : data.warningColor
        };`;

        // this.state.defaultColorVar[key] =
        //   item != 0
        //     ? this.colourBlend("#ffffff", syswarning, item / 10)
        //     : syswarning;
      }
      if (data.dangerColor) {
        const key = `--danger${item != 0 ? "-" + item : ""}`;
        colorStr += `${key}:${
          item != 0
            ? this.colourBlend("#ffffff", data.dangerColor, item / 10)
            : data.dangerColor
        };`;

        // this.state.defaultColorVar[key] =
        //   item != 0
        //     ? this.colourBlend("#ffffff", sysdanger, item / 10)
        //     : sysdanger;
      }
      if (data.infoColor) {
        const key = `--info${item != 0 ? "-" + item : ""}`;
        colorStr += `${key}:${
          item != 0
            ? this.colourBlend("#ffffff", data.infoColor, item / 10)
            : data.infoColor
        };`;

        // this.state.defaultColorVar[key] =
        //   item != 0 ? this.colourBlend("#ffffff", sysinfo, item / 10) : sysinfo;
      }
      // 选中active，foces颜色
      // this.state.defaultColorVar["--primary-active"] = this.colourBlend(
      //   "#000000",
      //   sysPrimary,
      //   0.1
      // );
      colorStr += `--primary-active:${this.colourBlend(
        "#ffffff",
        data.color,
        0.1
      )};`;
    });
    return colorStr;
  }
  /** 获取主题配置 */
  getTheme() {
    this.state.loading = true;
    _axios
      .get("/oauth2/api/lepton-theme-management/settings")
      .then((res) => {
        this.state.currentTheme = res.data;
        // this.state.sysSetting.systemTheme.color = colorData.color;
        this.changTheme();
      })
      .finally(() => {
        this.state.loading = false;
      });
  }
  /** 修改主题配置 */
  putTheme(data: InThemeItem) {
    this.changeColorSys(undefined);
    this.changeColorUser(undefined);
    const newtheme = { ...this.state.currentTheme };
    newtheme.style = data.style || 0;
    _axios
      .put("/oauth2/api/lepton-theme-management/settings", newtheme)
      .then(() => {
        if (this.autoSave) {
          // 保存配置
          this.saveData();
          this.saveDataUser();
        }
        this.getTheme();
      })
      .catch(() => {
        this.$notify.warning("主题设置失败");
      });
  }
  /** 动态添加主题 */
  changTheme() {
    const head = document.getElementsByTagName("head")[0]; //获取到head元素
    /** css root变量 */
    let styleStr: string = "";
    /** 查找主题数据 */
    let colorData: InThemeItem | undefined = undefined;
    if (this.state.userSetting.systemTheme.color) {
      // 个人用户自定义颜色
      colorData = this.state.userSetting.systemTheme;
    } else if (this.state.sysSetting.systemTheme.color) {
      // 系统自定义颜色
      colorData = this.state.sysSetting.systemTheme;
    } else {
      // 系统配色
      colorData = this.state.themeColorList.find(
        (val) => val.style == this.state.currentTheme.style
      );
    }
    if (colorData && colorData.color) {
      styleStr += this.getColorVar(colorData);
    }
    /** 主题变量style标签 */
    let styleAuto = document.getElementById("root-style-auto");
    if (!styleAuto) {
      styleAuto = document.createElement("style"); //创建style元素节点
      styleAuto.id = "root-style-auto";
      head.appendChild(styleAuto); //将style元素节点添加到head元素子节点下
    }
    styleAuto.innerHTML = `:root{${styleStr}}`;
  }
  /** 获取授权 */
  getgrantedPolicies() {
    const commonDatas = window.localStorage.getItem("commonDatas");
    const body = commonDatas ? JSON.parse(commonDatas) : "";
    if (
      body.SessionState &&
      body.SessionState.auth &&
      body.SessionState.auth.grantedPolicies
    ) {
      this.state.grantedPolicies =
        body.SessionState.auth.grantedPolicies[
          "LeptonThemeManagement.Settings"
        ];
      if (this.state.grantedPolicies) {
        this.getTheme();
      } else {
        // this.state.loading = true;
        // _axios
        //   .get("/oauth2/api/abp/application-configuration")
        //   .then((res) => {
        //     // console.log("获取主题配置");
        //   })
        //   .finally(() => {
        //     this.state.loading = false;
        //   });

        if (
          body.SessionState &&
          body.SessionState.setting &&
          body.SessionState.setting.values
        ) {
          const colorData = this.state.themeColorList.find(
            (val) =>
              val.name ==
              body.SessionState.setting.values["Volo.Abp.LeptonTheme.Style"]
          );
          if (colorData) {
            this.state.currentTheme.style = colorData.style;
          }
          this.changTheme();
        }
      }
    } else {
      setTimeout(() => {
        // console.log("获取主题权限");

        this.getgrantedPolicies();
      }, 1000 * 2);
    }
  }
  /** 获取配置数据 */
  getsetting() {
    this.state.loading = true;
    Promise.all([
      this.JsonSettingApi.jsonsettingGET(
        this.state.jsonData.name,
        undefined,
        undefined
      ),
      this.JsonSettingApi.jsonsettingGET(
        this.state.userjsonData.name,
        undefined,
        undefined
      ),
    ])
      .then((res) => {
        if (res[0]) {
          this.state.jsonData = res[0];
          const { value } = res[0];
          if (value) {
            this.state.sysSetting = Object.assign(
              this.state.sysSetting,
              JSON.parse(value)
            );
          }
        }
        if (res[1]) {
          this.state.userjsonData = res[1];
          const { value } = res[1];
          if (value) {
            this.state.userSetting = Object.assign(
              this.state.userSetting,
              JSON.parse(value)
            );
          }
        }
      })
      .finally(() => {
        this.state.loading = false;
        this.getgrantedPolicies();
      });
    // this.JsonSettingApi.jsonsettingGET(
    //   this.state.jsonData.name,
    //   undefined,
    //   undefined
    // )
    //   .then((res) => {
    //   })
  }
  /** 系统主题自定义颜色 */
  changeColorSys(color: string | undefined) {
    if (!color) {
      this.state.sysSetting.systemTheme.color = "";
    } else {
      this.changeColorUser(undefined);
    }
    this.saveData();
    this.$emit("changeSystem", this.state.sysSetting);
  }
  /** 个人用户主题自定义颜色 */
  changeColorUser(color: string | undefined) {
    if (!color) {
      this.state.userSetting.systemTheme.color = "";
    } else {
      this.state.userSetting.systemTheme.color = color;
    }

    // console.log(color);
    this.saveDataUser();
    this.$emit("changeSystemUser", this.state.userSetting);
  }
  /** 保存系统全局配置数据 */
  saveData() {
    // 系统配置
    const { id } = this.state.jsonData;
    const { value, ...newData } = this.state.jsonData;
    const body: UpdateJsonSettingDto = new UpdateJsonSettingDto({
      ...newData,
      value: JSON.stringify(this.state.sysSetting),
    });
    const resList = [];
    if (id) {
      resList.push(this.JsonSettingApi.jsonsettingPUT(id, body));
    } else {
      const tenantId: string | undefined = baseConfig.getCookie("__tenant");
      if (tenantId) {
        resList.push(this.JsonSettingApi.tenant(tenantId, body));
      }
    }
    Promise.all(resList)
      .then(() => {
        this.$notify.success("系统主题修改成功");
      })
      .catch(() => {
        this.$notify.error("系统主题修改失败");
      });
    this.saveDataUser(false);
  }
  /** 保存布局数据 */
  saveDataUser(showMsg: boolean = true) {
    const { id } = this.state.userjsonData;
    const { value, ...newData } = this.state.userjsonData;
    const body: UpdateJsonSettingDto = new UpdateJsonSettingDto({
      ...newData,
      value: JSON.stringify(this.state.userSetting),
    });
    const resList = [];
    if (id) {
      resList.push(this.JsonSettingApi.jsonsettingPUT(id, body));
    } else {
      const userId: string | undefined = baseConfig.getCookie("userId");
      if (userId) {
        resList.push(this.JsonSettingApi.user(userId, body));
      }
    }
    Promise.all(resList)
      .then(() => {
        if (showMsg) {
          this.$notify.success("成功！");
        }
      })
      .catch(() => {
        if (showMsg) {
          this.$notify.error("失败！");
        }
      })
      .finally(() => {
        this.getsetting();
      });
  }
  /** 监听 */
  /** 计算属性 */
  /** 主题默认色 */
  get predefineColors() {
    const list = ["#ff5c93", "#ee4f12", "#0096c7", "#ff9800"];
    const themeList = this.state.themeColorList.map((val) => val.color);
    return [...themeList, ...list];
  }
  /** 授权 */
  // get grantedPolicies() {
  //   const commonDatas = window.localStorage.getItem("commonDatas");
  //   const body = commonDatas ? JSON.parse(commonDatas) : "";
  //   if (
  //     body.SessionState &&
  //     body.SessionState.auth &&
  //     body.SessionState.auth.grantedPolicies
  //   ) {
  //     return body.SessionState.auth.grantedPolicies[
  //       "LeptonThemeManagement.Settings"
  //     ];
  //   } else {
  //     return false;
  //   }
  // }
}
