
































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import ElementUI from "element-ui";
import { baseConfig, _axios } from "vue-kst-auth";
import creatNavs from "../views/creatNavs.vue";
import NavFull from "@/components/NavFull.vue";
import Language from "@/components/Language.vue";
import ColorTheme from "@/components/ColorTheme.vue";
import SysSetting from "@/components/SysSetting.vue"; // 系统配置
// var QRCode = require("qrcode");
import QRCode from "qrcode";
// Vue.use(Button);
// Vue.use(Divider);
// Vue.use(Avatar);
import { InNavListItem, InSysSetting } from "@/plugins/ExportType";
import { JsonSettingApi, JsonSettingDto } from "@/plugins/JsonSetting";
Vue.use(ElementUI, { zIndex: 3000 });
interface INstyle {
  fontColor: string;
  activeColor: string;
  borderBottm: string;
}
interface State {
  item: any;
  /** 用户信息面板是否展开 */
  visible: boolean;
  /** cookie值 */
  cookie: any;
  /** 当前活动导航 */
  activeMenu: string;
  /** 导航列表 */
  navList: any;
  /**二维码地址 */
  qr: {
    /** 下载地址 */
    download: string;
    /** 版本 */
    tag?: string;
    /** 说明 */
    remark?: string;
    /** 更新时间 */
    creationTime?: string;
  };
  /** 导航样式 */
  navStyle: {
    [key: string]: INstyle;
  };
  /** 用户信息 */
  user: {
    /** 用户信息 */
    info: any;
    /** 用户所在部门信息 */
    organiza: string[];
    /** 头像 */
    imguer: string;
  };
  refresToken: any;
  /**清除定时器 */
  timer: any;
  step: boolean;
  /**父级id */
  parentId: string;
  /** 弹框状态 */
  drawer: boolean;
  /** 弹框状态是否完成 */
  drawerOpened: boolean;
  /** apk获取状态 */
  loadingApk: boolean;
  /** 租户系统配置 */
  sysSetting: InSysSetting | undefined;
  /** 个人用户系统配置 */
  // userSetting: InSysSetting | undefined;
  /** 系统配置数据，定义好后后期不能随意修改 */
  jsonData: JsonSettingDto;
  /** 个人用户配置数据，定义好后后期不能随意修改 */
  // userjsonData: JsonSettingDto;
}
@Component({
  components: {
    creatNavs,
    ColorTheme,
    NavFull,
    Language,
    SysSetting,
  },
})
export default class Layout02 extends Vue {
  /** 传送数据 */
  /** 左侧logo地址 */
  @Prop({ default: "/publicData/images/logo.png" }) logoUrl?: string;
  /**活动导航 */
  @Prop({ type: Number, default: 0 }) activeIndex!: number;
  /**教程的值 */

  /** 系统配置json API */
  JsonSettingApi: JsonSettingApi = new JsonSettingApi("/json_data", _axios);

  state: State = {
    item: [],
    parentId: "",
    step: false,
    timer: null,
    /** 用户信息面板是否展开 */
    visible: false,
    /** cookie值 */
    cookie: {},
    /** 当前活动导航 */
    activeMenu: "",
    /** 导航列表 */
    navList: [],
    /**刷新token的值 */
    refresToken: {
      refreshToken: baseConfig.getCookie("refreshToken"),
      userToken: baseConfig.getCookie("userToken"),
    },
    /**二维码地址 */
    qr: {
      /** 下载地址 */
      download: "",
      // /** 移动端浏览地址 */
      // mobile: ""
    },
    user: {
      info: {},
      organiza: [],
      imguer: "",
    },
    navStyle: {
      shengyansuo: {
        fontColor: "#ffffff",
        activeColor: "#3497ce",
        borderBottm: "#3497ce",
      },
    },
    drawer: false,
    drawerOpened: false,
    loadingApk: false,
    jsonData: new JsonSettingDto({
      name: "系统全局配置",
      display: "系统全局配置",
    }),
    // userjsonData: new JsonSettingDto({
    //   name: "用户全局配置",
    //   display: "用户全局配置",
    // }),
    sysSetting: undefined,
    // userSetting: undefined,
  };
  /** 生命周期 */

  created() {
    // 默认设置网页头为空
    window.document.title = "";
    this.refreshToken();
    let heatStr = document.getElementsByTagName("head")[0].innerHTML;
    if (!heatStr.includes("/message-center/components/msg.min.js")) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `/message-center/components/msg.min.js?v=${Date.now()}`;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
    if (!heatStr.includes("/publicData/styles/theme.css")) {
      let href = document.createElement("link");
      href.rel = "stylesheet";
      href.href = "/publicData/styles/theme.css";
      document.getElementsByTagName("head")[0].appendChild(href);
    }

    this.rewriteGetComputedStyle();
  }
  mounted() {
    this.getCookie();
    this.creatImg();
    this.getuserImg();
    this.getNavList();
  }
  /** 方法 */
  /** 重写window的一个方法，解决web components组件获取样式报错问题 */
  rewriteGetComputedStyle() {
    const getComputedStyle = window.getComputedStyle;
    window.getComputedStyle = function (element: any, property) {
      return getComputedStyle(element.host || element, property);
    };
  }
  // updated() {
  //   this.setNavActive();
  // }
  /**刷新token */
  refreshToken() {
    let time: any = baseConfig.getCookie("expiresIn");
    if (this.state.timer !== null) {
      clearInterval(this.state.timer);
    }
    this.state.timer = setTimeout(() => {
      if (baseConfig.getLoginState()) {
        _axios
          .post(
            "/oauth2/api/TerminalManagement/TokenAuth/refresh",
            this.state.refresToken
          )
          .then((res: any) => {
            this.getLogoInfo(res.data);
          });
      }
    }, time * 1000 - 60 * 1000);
  }
  /**获取登陆后的信息 */
  getLogoInfo(res: any) {
    let { expiresIn, accessToken, userToken, refreshToken } = res;
    /**计算token过期时间 */
    if (expiresIn)
      /**token类型存cookie */
      baseConfig.setCookie(baseConfig.token_type, "Bearer", {
        expires: expiresIn / (24 * 60 * 60),
        path: "/",
      });
    if (accessToken)
      /**token存cookie */
      baseConfig.setCookie(baseConfig.accessTokenKey, accessToken, {
        expires: expiresIn / (24 * 60 * 60),
        path: "/",
      });
    if (userToken)
      /**userToken存cookie */
      baseConfig.setCookie("userToken", userToken, {
        expires: expiresIn / (24 * 60 * 60),
        path: "/",
      });
    if (refreshToken)
      baseConfig.setCookie("refreshToken", refreshToken, {
        expires: expiresIn / (24 * 60 * 60),
        path: "/",
      });
    /**设置过期时间 */
    baseConfig.setCookie("expiresIn", expiresIn, {
      expires: expiresIn / (24 * 60 * 60),
      path: "/",
    });
  }
  /** 获取cookie */
  getCookie() {
    if (typeof document === "undefined") {
      return {};
    }
    function decode(s: string) {
      return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
    }

    let jar: any = {};
    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all.
    let cookies = document.cookie ? document.cookie.split("; ") : [];
    let i = 0;

    for (; i < cookies.length; i++) {
      let parts = cookies[i].split("=");
      let cookie = parts.slice(1).join("=");

      if (cookie.charAt(0) === '"') {
        cookie = cookie.slice(1, -1);
      }

      try {
        let name = decode(parts[0]);
        cookie = decode(cookie);
        jar[name] = cookie;
      } catch (e) {
        // console.log(e);
      }
    }

    this.state.cookie = { ...jar };
  }
  /** 获取当前用户相关数据 */
  getUserInfo() {
    this.state.user.info = {};
    this.state.user.organiza = [];
    /** 获取所有基础信息 */
    let commonDatas: any = JSON.parse(
      window.localStorage.getItem("commonDatas") || "{}"
    );
    /** 获取用户信息 */
    let userInfo: string = window.localStorage.getItem("userInfo") || "";
    if (userInfo) {
      let user: any = JSON.parse(userInfo);
      if (user.surname || user.name) {
        user["value"] = (user.surname || "") + (user.name || "");
      } else {
        user["value"] = user.userName;
      }
      this.state.user.info = user;
    } else {
      if (commonDatas.users && commonDatas.users.items) {
        const users: any[] = commonDatas.users.items || [];
        this.state.user.info =
          users.find((userItem) => userItem.id == this.state.cookie.userId) ||
          {};
      }
    }
    /** 获取用户所在部门信息 */
    let userInOrganiza: any[] = JSON.parse(
      window.localStorage.getItem("userInOrganiza") || "[]"
    );
    // 如果没有直接找到信息，从所有基础数据中获取用户所在部门信息
    if (userInOrganiza.length <= 0) {
      userInOrganiza = commonDatas.userInOrganiza
        ? commonDatas.userInOrganiza
        : [];
    }
    if (userInOrganiza.length > 0) {
      userInOrganiza.forEach((OrgVal) => {
        if (OrgVal.displayName) {
          this.state.user.organiza.push(OrgVal.displayName);
        }
      });
    }
  }
  /** 用户信息面板 */
  handmouseenter() {
    this.state.visible = true;
    this.getUserInfo();
  }
  /** 自动获取设置活动导航 */
  // setNavActive() {
  //   this.state.activeMenu = window.location.pathname.replace(/\/$/g, "");
  // }
  /** 生成二维码 */
  creatImg() {
    let url = `${this.host}/appDownload/${this.cookie.tenantName}.apk`;
    this.state.loadingApk = true;
    fetch(
      "/upgrade/api/uniapp-management/uniapp-version/public-latest/by-app-name/SMOP",
      {
        method: "GET",
        headers: {
          __tenant: baseConfig.getCookie("__tenant"),
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("1515", res);
        /** 下载地址 */
        const urlStr: string = res.pkgUrl;
        const index = urlStr.indexOf("/api");
        if (urlStr) {
          url = `${this.host}/files${urlStr.slice(
            index
          )}?__tenant=${baseConfig.getCookie("__tenant")}`;
        }
        this.state.qr.tag = res.tag;
        this.state.qr.remark = res.remark;
        if (res.lastModificationTime) {
          this.state.qr.creationTime = res.lastModificationTime
            ? res.lastModificationTime.replace(/T/, " ").slice(0, 19)
            : "";
        } else {
          this.state.qr.creationTime = res.creationTime
            ? res.creationTime.replace(/T/, " ").slice(0, 19)
            : "";
        }

        // apk下载地址
        (QRCode as any)
          .toDataURL(url)
          .then((url: any) => {
            this.state.qr.download = url;
            this.state.loadingApk = false;
          })
          .catch((err: any) => {
            this.state.qr.download = "";
            this.state.loadingApk = false;
          });
      })
      .catch(() => {
        // apk下载地址
        (QRCode as any)
          .toDataURL(url)
          .then((url: any) => {
            this.state.qr.download = url;
            this.state.loadingApk = false;
          })
          .catch((err: any) => {
            this.state.qr.download = "";
            this.state.loadingApk = false;
          });
      });
    // 移动端浏览地址
    // (QRCode as any)
    //   .toDataURL(this.mhost)
    //   .then((url: any) => {
    //     this.state.qr.mobile = url;
    //   })
    //   .catch((err: any) => {
    //     this.state.qr.mobile = "";
    //   });
  }
  /** 获取用户头像 */
  getuserImg() {
    _axios
      .get(
        `/oauth2/api/account/profile-picture/${baseConfig.getCookie("userId")}`
      )
      .then((res) => {
        // console.log(res);
        if (res.data && res.data.fileContent) {
          this.state.user.imguer = `data:image/png;base64,${res.data.fileContent}`;
        }
      });
  }

  /** 获取菜单列表 */
  getNavList() {
    if (baseConfig.getLoginState()) {
      Promise.all([
        this.JsonSettingApi.jsonsettingGET(
          this.state.jsonData.name,
          undefined,
          undefined
        ),
        // this.JsonSettingApi.jsonsettingGET(
        //   this.state.userjsonData.name,
        //   undefined,
        //   undefined
        // ),
        _axios.get(`/oauth2/api/menuManagement/tenantMenus/current-list`),
      ]).then((res) => {
        if (res[0]) {
          this.state.jsonData = res[0];
          const { value } = res[0];
          if (value) {
            this.state.sysSetting = JSON.parse(value);
            window.localStorage.setItem("systemSetting", value);
          }
        }
        // if (res[1]) {
        //   this.state.userjsonData = res[1];
        //   const { value } = res[1];
        //   if (value) {
        //     this.state.userSetting = Object.assign(
        //       this.state.userSetting,
        //       JSON.parse(value)
        //     );
        //   }
        // }
        if (res[1]) {
          this.state.navList = res[1].data.filter(
            (item: InNavListItem) => item.enabled
          );
        }
      });
      // _axios
      //   .get(`/oauth2/api/menuManagement/tenantMenus/current-list`)
      //   .then((res) => {
      //     this.state.navList = res.data.filter(
      //       (item: InNavListItem) => item.enabled
      //     );
      //   });
    }
  }
  /** 计算属性 */
  get cookie(): any {
    // let { name, img } = this.user;
    // this.state.cookie = Object.assign(this.state.cookie, { ...this.user });
    return this.state.cookie;
  }
  /** 域名 */
  get host() {
    return window.location.origin;
  }
  /** 移动端域名 */
  get mhost() {
    let protocol = window.location.protocol;
    let origin: string = window.location.host;
    if (origin.startsWith("www")) {
      origin = origin.replace(/www/g, "www.m");
    } else {
      origin = "m." + origin;
    }
    return protocol + "//" + origin;
  }
}
