














import { Component, Vue } from "vue-property-decorator";
interface State {
  /** 数据状态 */
  loading: boolean;
}
@Component
export default class Index extends Vue {
  /** 数据 */
  state: State = {
    loading: false,
  };
  /** 生命周期 */
  /** 方法 */
  /** 监听 */
  /** 计算属性 */
}
