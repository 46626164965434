


























import { InSysSetting } from "@/plugins/ExportType";
import { Component, Prop, Vue } from "vue-property-decorator";
interface State {
  /** 数据状态 */
  loading: boolean;
  /** 系统名称 */
  sysName: string;
  /** 网页名称显示 */
  webTitle: "导航名称" | "系统名称" | "系统和导航";
}
@Component
export default class Index extends Vue {
  /** 系统全局配置 */
  @Prop() sysSetting!: InSysSetting;
  /** 数据 */
  state: State = {
    loading: false,
    sysName: "",
    webTitle: "导航名称",
  };
  /** 生命周期 */
  created() {
    // console.log(this.sysSetting);
    
    this.state.sysName = this.sysSetting.sysName || "";
    this.state.webTitle = this.sysSetting.webTitle || "导航名称";
  }
  /** 方法 */
  /** 改变系统名称 */
  changeSysSetting() {
    const body = this.sysSetting;
    body.sysName = this.state.sysName || "";
    body.webTitle = this.state.webTitle || "导航名称";
    this.$emit("changeSystem", body);
  }
  /** 监听 */
  /** 计算属性 */
}
