
















































































































import { Component, Vue } from "vue-property-decorator";
import {
  UniappVersionApi,
  UniappVersionDto,
  CreateUpdateUniappVersionDto,
  UniappApi,
  CreateUpdateUniappDto,
} from "@/plugins/upgrade";
import { baseConfig, _axios } from "vue-kst-auth";
import Axios from "axios";
interface State {
  /** 数据状态 */
  loading: boolean;
  /** 编辑弹框状态 */
  visiable: boolean;
  /** app最新版本信息 */
  appInfo: UniappVersionDto;
  /** app编辑 */
  editData: UniappVersionDto;
  /** 用户信息 */
  userInfo: {
    /** 名称 */
    name: string;
  };
}
@Component
export default class Index extends Vue {
  /** app管理API */
  UniappApi: UniappApi = new UniappApi("/upgrade", _axios);
  /** app版本管理API */
  UniappVersionApi: UniappVersionApi = new UniappVersionApi("/upgrade", _axios);
  /** 数据 */
  state: State = {
    loading: false,
    visiable: false,
    appInfo: new UniappVersionDto({}),
    editData: new UniappVersionDto({
      tag: "",
      buildNumber: 1,
      remark: "",
      pkgUrl: "",
    }),
    userInfo: {
      name: "",
    },
  };
  /** 生命周期 */
  mounted() {
    Axios(
      "/upgrade/api/uniapp-management/uniapp-version/public-latest/by-app-name/SMOP",
      {
        method: "GET",
        headers: {
          __tenant: baseConfig.getCookie("__tenant"),
        },
      }
    )
      .then((res) => {
        this.getAppInfo();
        // console.log("自动创建02", res);
      })
      .catch((res) => {
        // console.log("自动创建01", res);
        const body: CreateUpdateUniappDto = new CreateUpdateUniappDto({
          name: "SMOP",
          isAvailable: true,
        });
        this.UniappApi.uniappPOST(body).then((appres) => {
          this.state.appInfo.appId = appres.id;
        });
      });
    const userStr = window.localStorage.getItem("userInfo");
    if (userStr) {
      try {
        const user = JSON.parse(userStr);
        this.state.userInfo.name = user.name
          ? `${user.surname}${user.name}`
          : user.userName;
      } catch (error) {
        // console.log(error);
      }
    }
  }
  /** 方法 */
  /** 获取app版本信息 */
  getAppInfo() {
    this.state.loading = true;
    this.UniappVersionApi.byAppName("SMOP")
      .then((res) => {
        // console.log(res);
        if (res.creationTime) {
          res.creationTime = res.creationTime.replace(/T/, " ").slice(0, 19);
        }
        if (res.lastModificationTime) {
          res.lastModificationTime = res.lastModificationTime
            .replace(/T/, " ")
            .slice(0, 19);
        }
        this.state.appInfo = res;
        this.state.editData.buildNumber = res.buildNumber;
      })
      .catch(() => {
        this.$notify({
          title: "提醒",
          type: "warning",
          message: "APP版本信息获取失败",
          position: "bottom-right",
        });
      })
      .finally(() => {
        this.state.loading = false;
      });
  }
  /** 文件上传成功 */
  fileSuccess(response: { fileToken: string }, file: any, fileList: any[]) {
    // console.log(response, file, fileList);
    if (response && response.fileToken) {
      this.state.editData.pkgUrl = `${window.location.protocol}//${window.location.host}/files/api/filing/file/download/${response.fileToken}`;
    }
  }
  /** 更新app */
  updateApp() {
    (this.$refs.editData as any).validate((valid: boolean) => {
      if (valid) {
        const body: CreateUpdateUniappVersionDto =
          new CreateUpdateUniappVersionDto(
            Object.assign(this.state.appInfo, this.state.editData)
          );
        // body.buildNumber = (body.buildNumber || 1) + 1;
        body.remark = `${this.state.userInfo.name}：${body.remark}`;
        // console.log(body);
        if (this.state.appInfo.id) {
          // 更新app
          this.UniappVersionApi.uniappVersionPUT(this.state.appInfo.id, body)
            .then((res) => {
              this.state.visiable = false;
              this.$notify.success("成功");
              this.getAppInfo();
            })
            .catch(() => {
              this.$notify.error("失败");
            });
        } else {
          // 添加app
          this.UniappVersionApi.uniappVersionPOST(body)
            .then((res) => {
              this.state.visiable = false;
              this.$notify.success("成功");
              this.getAppInfo();
            })
            .catch(() => {
              this.$notify.error("失败");
            });
        }
      }
    });
  }
  /** 监听 */
  /** 计算属性 */
  /** 获取headers */
  get headers() {
    return {
      __tenant: baseConfig.getCookie("__tenant"),
      Authorization: "Bearer " + baseConfig.getCookie("Abp.AuthToken"),
    };
  }
}
