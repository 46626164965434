














































































import { Component, Prop, Vue } from "vue-property-decorator";
import { InNavListItem, InSysSetting } from "@/plugins/ExportType";
import ColorTheme from "@/components/ColorTheme.vue";
import AppManagement from "@/components/AppManagement.vue";
import SystemName from "@/components/SystemName.vue";
import {
  JsonSettingApi,
  JsonSettingDto,
  UpdateJsonSettingDto,
} from "@/plugins/JsonSetting";
import { _axios, baseConfig } from "vue-kst-auth";
interface State {
  /** 数据状态 */
  loading: boolean;
  /** 基础数据请求完成 */
  loadingSetting: boolean;
  /** 系统配置数据，定义好后后期不能随意修改 */
  jsonData: JsonSettingDto;
  /** 个人用户配置数据，定义好后后期不能随意修改 */
  userjsonData: JsonSettingDto;
  /** 租户系统配置 */
  sysSetting: InSysSetting;
  /** 个人用户系统配置 */
  userSetting: InSysSetting;
}
@Component({
  components: {
    ColorTheme,
    AppManagement,
    SystemName,
  },
})
export default class Index extends Vue {
  /** 导航列表 */
  @Prop() navList!: Array<InNavListItem>;
  /** 系统配置json API */
  JsonSettingApi: JsonSettingApi = new JsonSettingApi("/json_data", _axios);
  /** 数据 */
  state: State = {
    loading: false,
    loadingSetting: false,
    sysSetting: {
      loginedUrl: "",
      kpiJumpSys: "",
      systemTheme: {
        name: "",
        color: "",
        successColor: "#4ECC7A",
        warningColor: "#F5BD5D",
        dangerColor: "#EC705F",
        infoColor: "#E6E6E6",
        style: 0,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      sysName: "",
      webTitle: "导航名称",
    },
    jsonData: new JsonSettingDto({
      name: "系统全局配置",
      display: "系统全局配置",
    }),
    userSetting: {
      loginedUrl: "",
      kpiJumpSys: "",
      systemTheme: {
        name: "",
        color: "",
        successColor: "#4ECC7A",
        warningColor: "#F5BD5D",
        dangerColor: "#EC705F",
        infoColor: "#E6E6E6",
        style: 0,
        boxedLayout: false,
        menuPlacement: 0,
        menuStatus: 1,
        publicLayoutStyle: 0,
      },
      sysName: "",
      webTitle: "导航名称",
    },
    userjsonData: new JsonSettingDto({
      name: "用户全局配置",
      display: "用户全局配置",
    }),
  };
  /** 生命周期 */
  mounted() {
    this.getsetting();
  }
  /** 方法 */
  /** 获取配置数据 */
  getsetting() {
    this.state.loading = true;
    Promise.all([
      this.JsonSettingApi.jsonsettingGET(
        this.state.jsonData.name,
        undefined,
        undefined
      ),
      this.JsonSettingApi.jsonsettingGET(
        this.state.userjsonData.name,
        undefined,
        undefined
      ),
    ])
      .then((res) => {
        if (res[0]) {
          this.state.jsonData = res[0];
          const { value } = res[0];
          if (value) {
            this.state.sysSetting = Object.assign(
              this.state.sysSetting,
              JSON.parse(value)
            );
          }
        }
        if (res[1]) {
          this.state.userjsonData = res[1];
          const { value } = res[1];
          if (value) {
            this.state.userSetting = Object.assign(
              this.state.userSetting,
              JSON.parse(value)
            );
          }
        }
      })
      .finally(() => {
        this.state.loading = false;
        this.state.loadingSetting = true;
      });
  }
  /** 保存布局数据 */
  saveData() {
    const { id } = this.state.jsonData;
    const { value, ...newData } = this.state.jsonData;
    const body: UpdateJsonSettingDto = new UpdateJsonSettingDto({
      ...newData,
      value: JSON.stringify(this.state.sysSetting),
    });

    // console.log("配置数据", body);
    if (id) {
      this.JsonSettingApi.jsonsettingPUT(id, body)
        .then(() => {
          this.$notify.success("成功！");
        })
        .catch(() => {
          this.$notify.error("失败！");
        });
    } else {
      const tenantId: string | undefined = baseConfig.getCookie("__tenant");
      if (tenantId) {
        this.JsonSettingApi.tenant(tenantId, body)
          .then((res) => {
            this.$notify.success("成功！");
          })
          .catch(() => {
            this.$notify.error("失败！");
          });
      }
    }
    this.saveDataUser();
  }
  /** 保存布局数据 */
  saveDataUser() {
    const { id } = this.state.userjsonData;
    const { value, ...newData } = this.state.userjsonData;
    const body: UpdateJsonSettingDto = new UpdateJsonSettingDto({
      ...newData,
      value: JSON.stringify(this.state.userSetting),
    });

    // console.log("配置数据", body);
    if (id) {
      this.JsonSettingApi.jsonsettingPUT(id, body)
        .then(() => {
          // this.$notify.success("成功！");
        })
        .catch(() => {
          this.$notify.error("失败！");
        });
    } else {
      const userId: string | undefined = baseConfig.getCookie("userId");
      if (userId) {
        this.JsonSettingApi.user(userId, body)
          .then((res) => {
            // this.$notify.success("成功！");
          })
          .catch(() => {
            this.$notify.error("失败！");
          });
      }
    }
  }
  /** 系统配置更改 */
  changeSystem(data: InSysSetting) {
    this.state.sysSetting = Object.assign(this.state.sysSetting, data);
    console.log("系统配置", this.state.sysSetting);

    // this.saveData();
  }
  /** 个人用户配置更改 */
  changeSystemUser(data: InSysSetting) {
    this.state.userSetting = Object.assign(this.state.userSetting, data);

    // this.saveData();
  }
  /** 监听 */
  /** 计算属性 */
  /** 过滤没有路径的导航 */
  get filterNavList() {
    return this.navList.filter((item) => item.path);
  }
}
